import './config.js'
import Vue from 'vue'
import 'ag-grid-enterprise'
import App from './App.vue'
import router from './routes/index'
import Toasted from 'vue-toasted'
import filters from './utils/filters/filter'
import { store } from './store/store'

require ('./utils/storage')
require('./validation/task')
require ('./utils/helpers')
import infiniteScroll from 'vue-infinite-scroll';

import {
	BootstrapVue, 
	ImagePlugin,
	ListGroupPlugin,
	CardPlugin,
	CarouselPlugin,
	ToastPlugin,
} from "bootstrap-vue";

Vue.use(BootstrapVue); 
Vue.use(ImagePlugin);
Vue.use(ListGroupPlugin);
Vue.use(CardPlugin);
Vue.use(CarouselPlugin);
Vue.use(ToastPlugin);

Vue.use(infiniteScroll);
Vue.use(Toasted)
export const bus = new Vue();

window.app_settings = window.config.VUE_APP_SETTINGS
window.VUE_APP_BASE_URL = window.config.VUE_APP_BASE_URL

const axios = require('axios');
window.axios =  axios.create({
	baseURL: '',
	timeout: 1800000,	// 1000*60*30
	headers: {
		'Content-Type': 'application/json',
		"Access-Control-Allow-Origin": "*",
	}
})

window.axios.CancelToken = axios.CancelToken
window.axios.isCancel = axios.isCancel
window.axios.interceptors.request.use(
	function(config) {
		const token = window.getStorage('user-token')
		if (token != null) {
			config.headers.Authorization = `Bearer ${token}`;
		}
		return config
	},
	function(err) {
		return Promise.reject(err)
	}
)

window.getFullName = function (value) {
	let str = value.toLowerCase().split("/");
	for (var i = 0; i < str.length; i++) {
		str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
	}
	return str.join(" ");
};

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
    let getEditModeCallback = window.inEditMode()
    if (getEditModeCallback == false) {
        next()
    }
    else {
        getEditModeCallback(next)
    }
});

new Vue({
  router,
  store,
  render: h => h(App)  
}).$mount('#app')

