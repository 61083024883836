<template>
  <div class="sidebar" :class="{'collapsed' : slideMenu, 'mobile-menu': windowSize <= breakPoint, 'default-menu': windowSize > breakPoint}">
    <a class="toggle-sidebar-button" @click="toggleMenu">
      <i class='material-icons'>
      menu
      </i>
    </a>
    <div class="logo">
        <img src="@/assets/images/MS Logo.png" alt="Mount Street Logo">
    </div>
    <div class="responsive-sidebar" :class="{'mobile-sidebar': mobileView}">
      <div class="navbar">
        <TheNavbarList class="main-node" :nodes="treeData"></TheNavbarList>
      </div>
    </div>
    <div class="page-overlay" v-if="mobileView" @click="slideMenu = true" :class="{'active': !slideMenu}">
    </div>
  </div>
</template>

<script>
import TheNavbarList from "./TheNavbarList";
// import UserManager from "@/library/users/manager";

export default {
  name: 'TheNavbar',
  props: 
   ['treeData'] ,
  components: {
    TheNavbarList
  },
  data() {
    return {
      slideMenu: false,
      windowSize: 0,
      breakPoint: 1024,
      isSlideDown: true,
      slideMobileMenu: false,
      mobileView: false,
      // showMenuItem: false
    }
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  mounted() {
    this.$nextTick(function() {
      window.addEventListener('resize',  this.handleResize);
      this.handleResize()
    })
  },
  methods: {
    toggleMenu() {
      this.slideMenu = !this.slideMenu
      var bodyTag = document.getElementById('app')
      if(this.slideMenu){
        bodyTag.classList.add('slideMenu')
      } else {
        bodyTag.classList.remove('slideMenu')
      }
      
    },
    refreshOnce(){
      // if( window.localStorage )
      // {
      //   if( !localStorage.getItem( 'firstLoad' ) )
      //   {
      //     localStorage[ 'firstLoad' ] = true;
      //     window.location.reload();
      //   }  

      //   else
      //     localStorage.removeItem( 'firstLoad' );
      // }
      if( window.sessionStorage )
      {
        if( !sessionStorage.getItem( 'firstLoad' ) )
        {
          sessionStorage[ 'firstLoad' ] = true;
          window.location.reload();
        }  

        else
          sessionStorage.removeItem( 'firstLoad' );
      }
    },
    handleResize() {
      //  this.refreshOnce()
         this.windowSize = window.innerWidth
      if (this.windowSize > this.breakPoint) {
        this.slideMobileMenu = false
        this.isSlideDown = true
        this.mobileView = false
        this.slideMenu = false
      } else if (this.windowSize <= this.breakPoint) {
        this.isSlideDown = false
        this.slideMobileMenu = false
        this.mobileView = true
        this.slideMenu = true
        var bodyTag = document.getElementById('app')
        bodyTag.classList.remove('slideMenu')
      }
    }
  }
};
</script>

<style lang="scss">
  @import '@/assets/scss/layout/_sidebar.scss'
</style>