import BaseManager from "@/library/system/manager";
import Model from "./model";
import BaseService from "../system/service";
class TaskManager extends BaseManager {
  constructor() {
    super();
    this.model = new Model();
    this.task_service = new BaseService("task_service");
    this.user_services = new BaseService("user_services");
    this.reference_service = new BaseService("reference_service");
    this.role_service = new BaseService("role_service");
    this.static_data_service = new BaseService("static_data_service");
    this.docvault_service = new BaseService("docvault_service");
    // this.docvault_auth_id = new BaseService("")
  }

  /**
   * Returns all the task templates.
   * @param {} callback
   */

  getTaskTemplatesManager(pageData, callback) {
    let params = pageData
    axios({
      url: this.task_service.getServiceUrl("taskTemplates", "get")+params,
      method: 'get',
      // data: data
    }).then(response => {
      callback(response.data)
    }).catch(error => {
      window.messages.push({type: 'error', shown:false, message: error.response.data.responseException.exceptionMessage})
      callback(error.response.data)
    })
  }

  getTaskTemplates(referenceId, callback) {
    this.task_service.fetch("taskTemplates", callback, {
      data: { referenceTypeId: referenceId, isActive: true },
      messages:{error:''}
    });
    // this.task_service.fetch('taskTemplates',callback,{'data':{'referenceTypeId':referenceId , 'status' : true}})
  }

  getWorkflowTaskTemplates(referenceId, callback) {
    this.task_service.fetch("taskTemplates", callback, {
      data: { referenceTypeId: referenceId, isActive: true, isWorkflow: true },
      messages:{error:''}
    });
    // this.task_service.fetch('taskTemplates',callback,{'data':{'referenceTypeId':referenceId , 'status' : true}})
  }

  getAllTemplates(callback) {
    this.task_service.fetch("taskTemplates", callback, {messages:{error:''}});
  }

  postTaskTemplates(data, callback) {
    this.task_service.fetch("taskTemplates", callback, {
      method: "post",
      data: data,
      messages:{success:'Template Added SuccessFully', error:''}
    });
  }

  editTaskTemplates(data, callback) {
    this.task_service.fetch("taskTemplates", callback, {
      method: "put",
      data: data,
      messages:{success:'Template Updated SuccessFully', error:''}
    });
  }

  deleteTaskTemplates(id, callback) {
    this.task_service.fetch("taskTemplates", callback, {
      method: "delete",
      params: { templateId: id },
      messages:{success:'Template Deleted SuccessFully', error:''}
    });
  }

  getTemplateDefaultTasksTemplateManager(pageData, callback) {
    let params = pageData
    axios({
      url: this.task_service.getServiceUrl("templateDefaultTasks", "get")+params,
      method: 'get',
    }).then(response => {
      callback(response.data)
    }).catch(error => {
      window.messages.push({type: 'error', shown:false, message: error.response.data.responseException.exceptionMessage})
      callback(error.response.data)
    })
  }

  getTemplateDefaultTasks(templateId, IsActive, callback) {
    this.task_service.fetch("templateDefaultTasks", callback, {
      data: { TemplateId: templateId, PageNumber: 1, PageSize: 500, IsActive:IsActive },
      messages:{error:''}
    });
  }

  getTemplateSlas(templateId, callback) {
    this.task_service.fetch("templateSlas", callback, {
      data: { TemplateId: templateId },
      messages: { error: '' }
    });
  }

  getTemplateRoles(templateId, callback) {
    this.task_service.fetch("templateRoles", callback, {
      data: {TemplateId: templateId},
      messages: {error: ''}
    });
  }

  workFlowLists(data, callback) {
    // this.task_service.fetch("workFlowLists", callback, {
    //   data: {PageNumber: 1, PageSize: 500 },
    //   messages:{error:''}
    // });
    axios({
      url: this.task_service.getServiceUrl("workflow", "get"),
      method: 'post',
      data: data
    }).then(response => {
      callback(response.data)
    }).catch(error => {
      window.messages.push({type: 'error', shown:false, message: error.response.data.responseException.exceptionMessage})
      callback(error.response.data)
    })
  }

  getWorkflows(callback) {
    this.task_service.fetch("workflow", callback, {
      method: "get",
      data: {PageNumber: 1, PageSize: 500 },
      messages:{error:''}
    });
  }

  addWorkflow(data, callback) {
    this.task_service.fetch("workflow", callback, {
      method: "post",
      data: data,
      messages:{success:'Workflow Added Successfully', error:''}
    });
  }

  editWorkflow(data, callback) {
    this.task_service.fetch("workflow", callback, {
      method: "put",
      data: data,
      messages:{success:'Workflow Updated Successfully', error:''}
    });
  }

  deleteWorkflow(id, callback) {
    let successMessage = '';
    if(id.length == 1) {
      successMessage = "Workflow";
    }else {
      successMessage = "Workflows";
    }

    this.task_service.fetch("workflow", callback, {
      method: "delete",
      data: id,
      messages:{success:`${successMessage} Deleted Successfully`, error:''}
    });
  }

  deleteWorkflowComments(id, callback) {
    this.task_service.fetch("workflowComments", callback, {
      method: "delete",
      params: { commentId: id },
      messages:{success:`Log Deleted Successfully`, error:''}
    });
  }

  postTemplateDefaultTasks(data, callback) {
    this.task_service.fetch("templateDefaultTasks", callback, {
      method: "post",
      data: data,
      messages:{success:'Task Added Successfully', error:''}
    });
  }

  updateTemplateDefaultTasks(data, callback) {
    this.task_service.fetch("templateDefaultTasks", callback, {
      method: "put",
      data: data,
      messages:{success:'Task Updated Successfully', error:''}
    });
  }

  deleteTemplateDefaultTasks(id, callback) {
    this.task_service.fetch("templateDefaultTasks", callback, {
      method: "delete",
      params: { templateTaskId: id },
      messages:{success:'Task Deleted Successfully', error:''}
    });
  }

  getTaskConfigurations(referenceId, searchValue, pageNumber, pageSize, callback) {
    this.task_service.fetch("taskConfigurations", callback, {
      data: {
        PageNumber: pageNumber,
        PageSize: pageSize,
        ReferenceTypeId: referenceId,
        status: true,
        Name: searchValue
      },
      messages:{error:''}
    });
  }

  getTaskConfigurationsTask(
    taskConfigurationId,
    pageNumber,
    pageSize,
    callback
  ) {
    this.task_service.fetch("taskConfigurations", callback, {
      data: {
        TaskConfigurationId: taskConfigurationId,
        PageSize: pageSize,
        PageNumber: pageNumber,
      },
      messages:{error:''}
    });
  }

  generateTask(data, callback) {
    this.task_service.fetch("taskGenerator", callback, {
      method: "post",
      data: data,
      messages:{success:'Task Generated',error:''}
    });
  }

  saveWorkFlowgenerateTask(data, fromVal, callback) {
    if(fromVal == "saveAndGenerate"){
      this.task_service.fetch("taskGenerator", callback, {
        method: "post",
        data: data,
         messages:{success:'Workflow Saved And Task Generated Successfully',error:''}
      })
    } else {
      this.task_service.fetch("taskGenerator", callback, {
        method: "post",
        data: data,
        messages:{success:'Task Generated Successfully',error:''}
      });
    }



  }

  saveGenerateTask(data, callback) {
    this.task_service.fetch("saveTask", callback, {
      method: "post",
      data: data,
      messages:{success:'Task Saved',error:''}
    });
  }

  saveRescheduleTask(data, routeName, route, callback) {
    let result = '';

    axios({
      url: this.task_service.getServiceUrl("saveTask", "post"),
      method: 'post',
      data: data
    }).then(response => {
      let str = "Click Here."
      // if(routeName == 'taskViewer') {
      //   result = str.link('/taskmanager/taskViewer/taskPanel/'+response.data.result[0].task.id)
      // }else {
      //   if(routeName == 'approvals') {
      //     result = str.link('/taskmanager/taskpanel-approval/'+response.data.result[0].task.id)
      //   }else if(routeName == 'my-approvals'){
      //     result = str.link('/taskmanager/taskpanel-approval/'+response.data.result[0].task.id)
      //   }else {
      //     result = str.link('/taskmanager/taskpanel-myTask/'+response.data.result[0].task.id)
      //   }
      // }
      result = str.link('/taskmanager/taskDetails/'+response.data.result[0].task.id)
      window.setStorage("previous-path", route.path);

      let successMsg = "Task has been rescheduled. To view the task" + ' ' + result
      window.messages.push({type: 'success', shown:false, message: successMsg})

      callback(response.data)
    }).catch(error => {
      window.messages.push({type: 'error', shown:false, message: error.response.data.responseException.exceptionMessage})
      callback(error.response.data)
    })
  }

  getAllTaskRoles(pageData, callback) {
    let params = pageData
     axios({
      url: this.reference_service.getServiceUrl("roleSupervisor", "get")+params,
      method: 'get',
    }).then(response => {
      callback(response.data)
    }).catch(error => {
      window.messages.push({type: 'error', shown:false, message: error.response.data.responseException.exceptionMessage})
      callback(error.response.data)
    })
  }

  getComments(taskId, callback) {
    this.task_service.fetch("taskComments", callback, {
      data: { taskId: taskId },
      messages:{error:''}
    });
  }

  getWorkFlowComments(workflowId, callback) {
    this.task_service.fetch("workflowComments", callback, {
      data: { workflowId: workflowId },
      messages:{error:''}
    });
  }

  getReferenceTypes(callback) {
    this.task_service.fetch("taskStaticData", callback, {
      data: { type: "referenceType" },
      messages: {error: ''}
    });
  }

  getTaskTypes(callback) {
    this.task_service.fetch("taskStaticData", callback, {
      data: { type: "taskType" },
      messages: {error: ''}
    });
  }

  getStatuses(callback) {
    this.task_service.fetch("taskStaticData", callback, {
      data: { type: "status" },
      messages: {error: ''}
    });
  }

  getAllTaskStatuses(taskGroup, callback) {
    let params = `?taskGroup=${taskGroup}`
    axios({
      url: this.task_service.getServiceUrl("getAllByTaskGroup", "get")+params,
      method: 'get',
    }).then(response => {
      callback(response.data)
    }).catch(error => {
      callback(error.response.data)
    })
  }

  getSupervisorRoles(callback) {
    this.reference_service.fetch("roleSupervisor", callback, {
      data: { IsActive: true },
      messages:{error:''}
    });
  }

  getAllRoles(statuses, callback) {
    this.reference_service.fetch("roleSupervisor", callback, {
      data: { isActive: statuses },
      messages:{error:''}
    });
  }

  saveRoles(data, callback) {
    this.reference_service.fetch("roleSupervisor", callback, {
      method: "post",
      data: data,
      messages:{success:'Role Added SuccessFully', error:''}
    });
  }
  editRoles(data, callback) {
    this.reference_service.fetch("roleSupervisor", callback, {
      method: "put",
      data: data,
      messages:{success:'Role Updated SuccessFully', error:''}
    });
  }
  // deleteRole(id, callback) {
  //   this.reference_service.fetch("roleSupervisor", callback, {
  //     method: "delete",
  //     params: { roleId: id },
  //     messages:{success:'Role Deleted SuccessFully', error:''}
  //   });
  // }

  deleteRole(data, callback) {
    let totalDataCount = data.length
    let successMsg = ""
    if (totalDataCount > 1) successMsg = 'Roles Status Set to Inactive'
    else successMsg = 'Role Status Set to Inactive'

    this.reference_service.fetch("roleSupervisor", callback, {
      method: "delete",
      data: data,
      messages:{success: successMsg, error:''}
    });
  }

  undeleteRole(data, callback) {
    let totalDataCount = data.length
    let successMsg = ""
    if (totalDataCount > 1) successMsg = 'Roles Status Set to Active'
    else successMsg = 'Role Status Set to Active'

    this.reference_service.fetch("undeleteRoles", callback, {
      method: "put",
      data: data,
      messages:{success: successMsg, error:''}
    });
  }

  getRollConventions(callback) {
    this.static_data_service.fetch("staticData",
    callback, {
    data: { type: "dateRollConvention" },
    messages: {error: ''}});
  }

  getTemplatesList(templateId, callback) {
    this.task_service.fetch("templateManager", callback, {
      data: { templateId: templateId },
      messages: {error: ''}
    });
  }

  getWorkbenchApprovalTasks(userId, pageData, statuses, data,  callback) {
    let params = `?UserId=${userId}&IsPrimary=false&IsSecondary=false&IsApprover=true&${pageData}`

    if(statuses.length) {
      for(status of statuses) {
        params += `&Statuses=${status}`
      }
    }

    axios({
      url: this.task_service.getServiceUrl("userTask", "post")+params,
      method: 'post',
      data: data
    }).then(response => {
      callback(response.data)
    }).catch(error => {
      window.messages.push({type: 'error', shown:false, message: error.response.data.responseException.exceptionMessage})
      callback(error.response.data)
    })
  }

  getMyTasks(userId, pageData, statuses, data, taskType, callback) {
    let params = `?UserId=${userId}&IsPrimary=true&IsSecondary=true&IsApprover=false&${pageData}`

    if(statuses.length) {
      for(status of statuses) {
        params += `&Statuses=${status}`
      }
    }

    params += `&TaskTypes=${taskType}`

    axios({
      url: this.task_service.getServiceUrl("userTask", "post")+params,
      method: 'post',
      data: data
    }).then(response => {
      callback(response.data)
    }).catch(error => {
      window.messages.push({type: 'error', shown:false, message: error.response.data.responseException.exceptionMessage})
      callback(error.response.data)
    })
  }

  getServiceRequestsTasks(userId, pageData, statuses, data, taskType, callback) {
    let params = `?UserId=${userId}&IsPrimary=true&IsSecondary=true&IsApprover=false&${pageData}`

    if(statuses.length) {
      for(status of statuses) {
        params += `&Statuses=${status}`
      }
    }

    params += `&TaskTypes=${taskType}`

    axios({
      url: this.task_service.getServiceUrl("userTask", "post")+params,
      method: 'post',
      data: data
    }).then(response => {
      callback(response.data)
    }).catch(error => {
      window.messages.push({type: 'error', shown:false, message: error.response.data.responseException.exceptionMessage})
      callback(error.response.data)
    })
  }

  getTaskViewerTasks(userId, pageData, statuses, loanTypes, referenceKey, taskReferenceId, data, workflowId, callback) {
    let params = pageData

    if(userId) {
      params += `&UserId=${userId}`
    }

    // if(loanTypes.length) {
    //   for(var loan of loanTypes) {
    //     if(loan == null){
    //       params += `&LoanTypes=`
    //     } else {
    //       params += `&LoanTypes=${loan}`
    //     }
    //   }
    // }

    if(statuses != undefined && statuses.length > 0) {
      for(let status of statuses) {
        params += `&Statuses=${status}`
      }
    }

    // if(referenceTypeId) {
    //   params += `&ReferenceTypeId=${referenceTypeId}`
    // }

    if(taskReferenceId) {
      params += `&ReferenceId=${taskReferenceId}`
    }

    if(referenceKey) {
      params += `&ReferenceKey=${referenceKey}`
    }

    if(workflowId != null) {
      params += `&WorkflowId=${workflowId}`
    }

    axios({
      url: this.task_service.getServiceUrl("userTask", "post")+params,
      method: 'post',
      data: data
    }).then(response => {
      callback(response.data)
    }).catch(error => {
      console.log('getTaskViewerTasks error: ', error);
      window.messages.push({type: 'error', shown:false, message: error.response.data.responseException.exceptionMessage})
      callback(error.response.data)
    })

  }

  getTasksDataList(pageData, statuses, searchValue, callback) {
    let params = pageData

    if(statuses.length) {
      for(status of statuses) {
        params += `&Statuses=${status}`
      }
    }

    if(searchValue) {
      params += `&TaskName=${searchValue}`
    }

    axios({
      url: this.task_service.getServiceUrl("userTask", "post")+params,
      method: 'post',
      data: []
    }).then(response => {
      callback(response.data)
    }).catch(error => {
      callback(error.response.data)
    })
  }

  // workbench taskpanel select task data
  getMyTasksData(userId, pageData, statuses, searchValue, callback) {
    let params = `?UserId=${userId}&IsPrimary=true&IsSecondary=true&IsApprover=false&${pageData}`

    if(statuses.length) {
      for(status of statuses) {
        params += `&Statuses=${status}`
      }
    }

    if(searchValue) {
      params += `&TaskName=${searchValue}`
    }

    axios({
      url: this.task_service.getServiceUrl("userTask", "post")+params,
      method: 'post',
      data: []
    }).then(response => {
      callback(response.data)
    }).catch(error => {
      callback(error.response.data)
    })
  }

  getRefobjectList(pageData, callback) {
    let params = pageData
    axios({
      url: this.task_service.getServiceUrl("refObjectRoles", "get")+params,
      method: 'get',
    }).then(response => {
      callback(response.data)
    }).catch(error => {
      window.messages.push({type: 'error', shown:false, message: error.response.data.responseException.exceptionMessage})
      callback(error.response.data)
    })
  }

  saveRefObjectRole(data, callback) {
    this.task_service.fetch("refObjectRoles", (response, error) => {
      callback(response, error);
    },
    {method: "post", data: data,
    messages: {success: 'Reference Object Roles saved successfully', error:''}
    })
  }

  updateRefObjectRole(data, callback) {
    this.task_service.fetch("refObjectRoles", (response, error) => {
      callback(response, error);
    },
    {method: "put", data: data,
    messages: {success: 'Changes saved successfully', error: ''}
    })
  }

  deleteRefObjectRole(refId, callback) {
    this.task_service.fetch(
      "refObjectRoles",
      (response, error) => {
        callback(response, error);
      },
      {method: "delete",
      params: {referenceObjectRoleId: refId},
      messages: {success:'Reference Object Role deleted successfully', error:''}
      }
    );
  }

  saveComments(data, callback) {
    this.task_service.fetch(
      "taskComments",
      (response, error) => {
        callback(response, error);
      },
      { method: "post", data: data, messages: {success:'Log saved successfully!', error:''}}
    );
  }

  saveWorkflowComments(data, callback) {
    this.task_service.fetch(
      "workflowComments",
      (response, error) => {
        callback(response, error);
      },
      { method: "post", data: data, messages: {success:'Log saved successfully!', error:''}}
    );
  }

  rejectLog(data, callback) {
    this.task_service.fetch(
      "taskComments",
      (response, error) => {
        callback(response, error);
      },
      { method: "post", data: data, messages: {error:''}}
    );
  }

  saveCommentOnUserAction(data, callback) {
    this.task_service.fetch(
      "taskComments",
      (response, error) => {
        callback(response, error);
      },
      { method: "post", data: data, messages: {error: ''}}
    );
  }

  updateComments(data, callback) {
    this.task_service.fetch(
      "taskComments",
      (response, error) => {
        if (response && response.isError == false) {
        }
        callback(response, error);
      },
      { method: "put", data: data, messages: {success:'Log updated successfully!', error: ''}}
    );
  }

  updateWorkflowComments(data, callback) {
    this.task_service.fetch(
      "workflowComments",
      (response, error) => {
        if (response && response.isError == false) {
        }
        callback(response, error);
      },
      { method: "put", data: data, messages: {success:'Log updated successfully!', error: ''}}
    );
  }

  deleteComments(commentId, callback) {
    this.task_service.fetch(
      "taskComments",
      (response, error) => {
        callback(response, error);
      },
      { method: "delete", params: { commentId: commentId }, messages:{success:'Log deleted successfully!', error:''} }
    );
  }

  getAssignmentType(callback) {
    this.task_service.fetch("assignmentType", callback,
      {messages: {error: ''}}
      );
  }

  getPreferences(userId, updateLocaleSettings, showError = false, callback) {
    let successMessage = '';
    if(updateLocaleSettings) {
      successMessage = 'Setttings saved successfully!'
    }

    let params = `?userId=${userId}`;
    axios({
      url: this.user_services.getServiceUrl("userPreferences", "get")+params,
      method: 'get',
    }).then(response => {
      callback(response.data)
      if(updateLocaleSettings) {
        window.messages.push({type: 'success', shown:false, message: successMessage});
      }
    }).catch(error => {
      if(updateLocaleSettings) {
        if(error.response.data.responseException.exceptionMessage = "No settings found for user"){
          // window.messages.push({type: 'error', shown:false, message: ''})
        } else {
          window.messages.push({type: 'error', shown:false, message: error.response.data.responseException.exceptionMessage})
        }
      } else if (updateLocaleSettings == false && showError == true) {
        if(error.response.data.responseException.exceptionMessage = "No settings found for user"){
          // window.messages.push({type: 'error', shown:false, message: ''})
        } else {
          window.messages.push({type: 'error', shown:false, message: error.response.data.responseException.exceptionMessage})
        }
      }
      callback(error.response.data)
    })
  }

  updatePreferences(userId, data, showMsg = true, callback) {
    let params = `?userId=${userId}`;
    let successMessage = 'Setttings saved successfully!'
    if (!showMsg) successMessage = ''
    axios({
      url: this.user_services.getServiceUrl("userPreferences", "post")+params,
      method: 'post',
      data: data
    }).then(response => {
      callback(response.data)
      if(showMsg == true) {
        window.messages.push({type: 'success', shown:false, message: successMessage});
      }
    }).catch(error => {
      window.messages.push({type: 'error', shown:false, message: error.response.data.responseException.exceptionMessage})
      callback(error.response.data)
    })
  }


  getTasks(pageData, callback) {
    let params = pageData
    axios({
      url: this.task_service.getServiceUrl("taskConfigurations", "get")+params,
      method: 'get',
    }).then(response => {
      callback(response.data)
    }).catch(error => {
      window.messages.push({type: 'error', shown:false, message: error.response.data.responseException.exceptionMessage})
      callback(error.response.data)
    })
  }

  saveTaskConfigurations(data, callback) {
    this.task_service.fetch("taskConfigurations", callback, {
      method: "post",
      data: data,
      messages: {success: 'Task saved successfully', error: ''}
    });
  }

  extractTasks(data, callback) {
    this.task_service.fetch("taskExtract", callback, {
      method: "get",
      data: data,
      messages: {success: 'Task exported successfully', error: ''}
    });
  }

  // exportServiceRequestSlaReport(data, callback) {
  //   this.task_service.fetch("sla", callback, {
  //     method: "post",
  //     data: data,
  //     messages: {success: 'Tasks exported successfully', error: ''}
  //   });
  // }

  exportServiceRequestSlaReport(data, callback) {
    let params = '';
    for(var [key, value] of Object.entries(data)) {
      if(value !== null) {
        params += `&${key}=${value}`;
      }
    }

    axios({
      url: this.task_service.getServiceUrl("sla", "post")+'?'+params,
      method: 'post',
    }).then(response => {
      window.messages.push({type: 'success', shown:false, message: response.data.message})
      callback(response.data)
    }).catch(error => {
      window.messages.push({type: 'error', shown:false, message: error.response.data.responseException.exceptionMessage})
      callback(error.response.data)
    })
  }

  getForWebWithSLA(pageData, callback) {
    let params = pageData
    axios({
      url: this.task_service.getServiceUrl("slaWeb", "post")+params,
      method: 'post',
    }).then(response => {
      callback(response.data)
    }).catch(error => {
      window.messages.push({type: 'errorOnTop', shown: false, message: error.response.data.responseException.exceptionMessage})
      callback(error.response.data)
    })
  }

  updateTaskConfiguration(data, callback) {
    this.task_service.fetch(
      "taskConfigurations",
      (response, error) => {
        if (response && response.isError == false) {
        }
        callback(response, error);
      },
      { method: "put", data: data, messages: {success: 'Changes saved successfully', error: ''} }
    );
  }

  deleteTaskConfiguration(configurationId, callback) {
    this.task_service.fetch("taskConfigurations", callback, {
      method: "delete",
      params: { taskConfigurationId: configurationId },
      messages: {success: 'Task Deleted successfully', error: ''}
    });
  }

  getTaskConfigurationsByReference(referenceId, searchValue, PageNumber, PageSize, callback) {
    this.task_service.fetch("taskConfigurations", callback, {
      data: {
        ReferenceTypeId: referenceId,
        PageNumber,
        PageSize,
        Name: searchValue
      },
      messages: {error: ''}
    });
  }

  getNewTaskConfigurationsByReference(referenceId, searchValue, PageNumber, PageSize, taskType, callback) {
    this.task_service.fetch("taskConfigurations", callback, {
      data: {
        ReferenceTypeId: referenceId,
        PageNumber,
        PageSize,
        Name: searchValue,
        TaskTypes: taskType
      },
      messages: {error: ''}
    });
  }

  getTaskConfigurationTasks(taskConfigurationId, callback) {
    this.task_service.fetch("taskConfigurations", callback, {
      data: { taskConfigurationId: taskConfigurationId },
      messages: {error: ''}
    });
  }

  getBusinessDays(status, callback) {
    this.static_data_service.fetch("business_calendars", callback, {
      data: { IsActive: status },
      messages: {error: ''}
    });
  }

  getTaskStatuses(callback) {
    this.task_service.fetch("taskStaticData", callback, {
      data: { type: "status" },
      messages: {error: ''}
    });
  }

  bulkMarkAsComplete(data, callback) {
    let totalCount = data.length;

    let successMessage = '';
    if(totalCount == 1 || totalCount == 0)
      successMessage = '1 task has been successfully marked as completed.';
    else
      successMessage = `${totalCount} tasks have been successfully marked as completed.`;

    this.task_service.fetch('bulkActions', callback, {
      method: "post",
      data: data,
      messages: {success: `${successMessage}`, error: ''}
    });
  }

  bulkMarkAsByPass(data, callback) {
    let totalCount = data.length;

    let successMessage = '';
    if(totalCount == 1)
      successMessage = '1 task has been successfully bypassed.';
    else
      successMessage = `${totalCount} tasks have been successfully bypassed.`;

    this.task_service.fetch('bulkActions', callback, {
      method: "post",
      data: data,
      messages: {success: `${successMessage}`, error: ''}
    });
  }

  bulkMarkAsDelete(data, callback) {
    let totalCount = data.length;

    let successMessage = '';
    if(totalCount == 1 || totalCount == 0)
      successMessage = '1 task has been successfully deleted.';
    else
      successMessage = `${totalCount} tasks have been successfully deleted.`;

    this.task_service.fetch('bulkActions', callback, {
      method: "post",
      data: data,
      messages: {success: `${successMessage}`, error: ''}
    });
  }

  bulkApproveTasks(data, callback) {
    let totalCount = data.length;

    let successMessage = '';
    if(totalCount == 1 || totalCount == 0)
      successMessage = '1 task has been successfully approved.';
    else
      successMessage = `${totalCount} tasks have been successfully approved.`;

    this.task_service.fetch('bulkActions', callback, {
      method: "post",
      data: data,
      messages: {success: `${successMessage}`, error: ''}
    });
  }

  updateUserRoles(data, callback) {
    let totalCount = data.length;

    let successMessage = '';
    if(totalCount == 1 || totalCount == 0)
      successMessage = '1 task has been successfully updated.';
    else
      successMessage = `${totalCount} tasks have been successfully updated.`;

    this.task_service.fetch('bulkActions', callback, {
      method: "post",
      data: data,
      messages: {success: `${successMessage}`, error: ''}
    });
  }

  postTask(data, callback) {
    this.task_service.fetch("newTask", callback, {
      method: "post",
      data: data,
      messages: {success:'The task has been created successfully!', error: ''}
    });
  }

  postServiceRequest(data, callback) {
    this.task_service.fetch("newServiceRequest", callback, {
      method: "post",
      data: data,
      messages: { success: 'The request has been created successfully', error: '' }
    });
  }

  updateTask(action, data, callback) {
    if(action == 'reject') {
      this.task_service.fetch("userTask", callback, {
        method: "put",
        data: data,
        messages: {success:'Task has been rejected successfully!', error: ''}
      });
    }else if(action == 'markAsComplete') {
      this.task_service.fetch("userTask", callback, {
        method: "put",
        data: data,
        messages: {success:'Task has been marked as completed successfully!', error: ''}
      });
    }else if(action == 'byPass') {
      this.task_service.fetch("userTask", callback, {
        method: "put",
        data: data,
        messages: {success:'Task Bypass Requested.', error: ''}
      });
    }else if(action == 'approve') {
      this.task_service.fetch("userTask", callback, {
        method: "put",
        data: data,
        messages: {success:'Task has been approved successfully!', error: ''}
      });
    }else {
      this.task_service.fetch("userTask", callback, {
        method: "put",
        data: data,
        messages: {success:'Task updated successfully!', error: ''}
      });
    }
  }

  updateTaskBeforeAction(data, callback) {
    this.task_service.fetch("userTask", callback, {
      method: "put",
      data: data,
      messages: {error: ''}
    });
  }

  updateTaskBeforeDelete(data, callback) {
    this.task_service.fetch("userTask", callback, {
      method: "put",
      data: data,
      messages: {error: '', success:'Task sent for delete approval.'}
    });
  }

  deleteTask(taskId, callback) {
    this.task_service.fetch("userTask", callback, {
      method: "delete",
      params: {taskId: taskId},
      messages: {success:'Task deleted successfully!',error: ''}
    });
  }

  getTaskById(taskId, callback) {
    this.task_service.fetch("taskById", callback, {data: {taskId: taskId}, messages: {error: ''}});
  }

  getActionsByTaskId(taskId, callback) {
    this.task_service.fetch("actionsByTaskId", callback, {data: {taskId: taskId}, messages: {error: ''}});
  }

  getBusinessCalendars(callback){
     this.static_data_service.fetch('business_calendars', callback,
      {messages: {error: ''},
      data: { IsActive: true },

    })
  }

  getDateOffset(data, callback) {
    this.static_data_service.fetch('dateOffset', callback, {
      method: "post",
      data: data,
      messages: {error: ''}
    });
  }

  getReferenceAssignments(callback){
     this.task_service.fetch('referenceAssignment', callback, {messages: {error: ''}})
  }

  getLogTypes(callback) {
    this.static_data_service.fetch('staticData', callback, {
      data: { type: "logEntryType" },
      messages: { error: '' }
    })
  }

  getTaskCount(data, val, callback) {
    let dataObj = {
      "idList": data,
      "label": val
    }

    let apiData = [dataObj];

    this.task_service.fetch("taskCount", callback,
    {
      method: "post",
      data: apiData,
      messages: {error:''}
    });
  }

  // getTaskManagerUser(callback) {
  //   this.docvault_service.fetch("users", callback, {
  //     method: "post",
  //     data: { email: `${window.config.docvault.email}` },
  //     messages: { error: '' },
  //     headers: {'Content-Type': 'multipart/form-data' }
  //   })

  //   // let params = `?email=${window.config.docvault.email}`;

  //   // axios({
  //   //   url: this.docvault_service.getServiceUrl("users", "post")+params,
  //   //   method: 'post',
  //   // }).then(response => {
  //   //   callback(response.data)
  //   // }).catch(error => {
  //   //   window.messages.push({type: 'error', shown:false, message: error.response.data.responseException.exceptionMessage})
  //   //   callback(error.response.data)
  //   // })
  // }

  getTaskManagerUser(callback) {
    let data = new FormData();
    data.append('email', `${window.config.docvault.email}`);

    axios({
      url: this.docvault_service.getServiceUrl("users", "post"),
      method: 'post',
      data: data,
      headers: { 'Content-Type': 'multipart/form-data' },
      transformRequest: [function (data, headers) {
        delete headers['Access-Control-Allow-Origin'];
        return data
      }],
    }).then(response => {
      callback(response.data)
    }).catch(error => {
      callback(error.response.data)
    })
  }

  docvaultAuthenticateUser(callback) {
    var authId = window.config.VUE_APP_DOCVAULT_AUTHID
    this.task_service.fetch("docvaultAuthentication", callback,
    {data: {id: authId}, messages: {error: ''}}
    );
  }

  authenticateUser(callback) {
    let data = new FormData();
    data.append('client_id', `${window.config.docvault.client_id}`);
    data.append('client_secret', `${window.config.docvault.client_secret}`);
    data.append('realms', `${window.config.docvault.realms}`);
    data.append('identity', `${window.config.docvault.email}`);

    axios({
      url: this.docvault_service.getServiceUrl("user_authenticate", "post"),
      method: 'post',
      data: data,
      headers: { 'Content-Type': 'multipart/form-data' },
      transformRequest: [function (data, headers) {
        delete headers['Access-Control-Allow-Origin'];
        return data
      }],
    }).then(response => {
      callback(response.data)
    }).catch(error => {
      callback(error.response.data)
    })
  }

  searchProject(dealId, userToken, callback) {
    let data = new FormData();
    data.append('tags', `{"deal_uuid": "${dealId}"}`);
    // console.log('sp', window, window.docvault_user_token)
    axios({
      url: this.docvault_service.getServiceUrl("projects", "post"),
      method: 'post',
      data: data,
      transformRequest: [function (data, headers) {
        headers['Authorization'] = userToken;
        delete headers['Access-Control-Allow-Origin'];
        return data
      }],
      headers: {
        'Content-Type': 'multipart/form-data',
        "x-token-type": "Access",
      }
    }).then(response => {
      callback(response.data)
    }).catch(error => {
      callback(error.response)
    })
  }

  getAllProjects(pageNumber, userToken, callback) {
    let data = new FormData();
    data.append('page', pageNumber);
    axios({
      url: this.docvault_service.getServiceUrl("projects", "get"),
      method: 'get',
      data: data,
      transformRequest: [function (data, headers) {
        headers['Authorization'] = userToken;
        delete headers['Access-Control-Allow-Origin'];
        return data
      }],
      headers: {
        'Content-Type': 'multipart/form-data',
        "x-token-type": "Access",
      }
    }).then(response => {
      callback(response.data)
    }).catch(error => {
      callback(error.response.data)
    })
  }

  getAllProjectsListing(pageNumber, userToken, callback) {
    let params = `?page=${pageNumber}`
    let data = new FormData();
    data.append('page', pageNumber);
    axios({
      url: this.docvault_service.getServiceUrl("projectsListing", "get")+params,
      method: 'get',
      data: data,
      transformRequest: [function (data, headers) {
        headers['Authorization'] = userToken;
        delete headers['Access-Control-Allow-Origin'];
        return data
      }],
      headers: {
        'Content-Type': 'multipart/form-data',
        "x-token-type": "Access",
      }
    }).then(response => {
      callback(response.data)
    }).catch(error => {
      callback(error.response.data)
    })
  }

  // create project on docvault
  createProject(projectName, dealId, userToken, callback) {
    let data = new FormData();
    data.append('name', projectName);
    data.append('tags', `{"deal_uuid": "${dealId}"}`);
    axios({
      url: this.docvault_service.getServiceUrl("projects", "post"),
      method: 'post',
      data: data,
      transformRequest: [function (data, headers) {
        headers['Authorization'] = userToken;
        delete headers['Access-Control-Allow-Origin'];
        return data
      }],
      headers: {
        'Content-Type': 'multipart/form-data',
        "x-token-type": "Access",
      }
    }).then(response => {
      callback(response.data)
    }).catch(error => {
      window.messages.push({type: 'error', shown:false, message: error.response.data.responseException.exceptionMessage})
      callback(error.response.data)
    })
  }

  // create project Folder on docvault
  createProjectFolder(projectName, projectSlug, parentType, userToken, callback) {
    let data = new FormData();
    data.append('name', projectName);
    data.append('parent_slug', projectSlug);
    data.append('parent_type', parentType);
    // data.append('tags', `{"deal_uuid": "${dealId}"}`);
    axios({
      url: this.docvault_service.getServiceUrl("projects", "post"),
      method: 'post',
      data: data,
      transformRequest: [function (data, headers) {
        headers['Authorization'] = userToken;
        delete headers['Access-Control-Allow-Origin'];
        return data
      }],
      headers: {
        'Content-Type': 'multipart/form-data',
        "x-token-type": "Access",
      }
    }).then(response => {
      callback(response.data)
    }).catch(error => {
      callback(error.response)
    })
  }

  // search Folder
  searchProjectFolder(folderName, parentSlug, parentType, pageNumber, userToken, callback) {
    let params = `?page=${pageNumber}`
    let data = new FormData();
    data.append('name', folderName);
    data.append('type', parentType);
    data.append('parent_slug', parentSlug);
    axios({
      url: this.docvault_service.getServiceUrl("search", "post")+params,
      method: 'post',
      data: data,
      transformRequest: [function (data, headers) {
        headers['Authorization'] = userToken;
        delete headers['Access-Control-Allow-Origin'];
        return data
      }],
      headers: {
        'Content-Type': 'multipart/form-data',
        "x-token-type": "Access",
      }
    }).then(response => {
      callback(response.data)
    }).catch(error => {
      callback(error.response)
    })
  }

  // Search Folder by name
  searchProjectFolderByName(folderName, exact, userToken, callback) {
    let data = new FormData();
    data.append('name', folderName);
    data.append('exact', exact);
    axios({
      url: this.docvault_service.getServiceUrl("searchProject", "post"),
      method: 'post',
      data: data,
      transformRequest: [function (data, headers) {
        headers['Authorization'] = userToken;
        delete headers['Access-Control-Allow-Origin'];
        return data
      }],
      headers: {
        'Content-Type': 'multipart/form-data',
        "x-token-type": "Access",
      }
    }).then(response => {
      callback(response.data)
    }).catch(error => {
      callback(error.response)
    })
  }

  // search project
  searchProjectPopUp(projectName, userToken, callback) {
    let data = new FormData();
    data.append('name', projectName);
    axios({
      url: this.docvault_service.getServiceUrl("searchProject", "post"),
      method: 'post',
      data: data,
      transformRequest: [function (data, headers) {
        headers['Authorization'] = userToken;
        delete headers['Access-Control-Allow-Origin'];
        return data
      }],
      headers: {
        'Content-Type': 'multipart/form-data',
        "x-token-type": "Access",
      }
    }).then(response => {
      callback(response.data)
    }).catch(error => {
      window.messages.push({type: 'error', shown:false, message: error.response.data.responseException.exceptionMessage})
      callback(error.response.data)
    })
  }

  // tag project
  tagProject(projectId, dealId, userToken, callback) {
    let data = new FormData();
    data.append('project_id', projectId);
    data.append('tag', "deal_uuid");
    data.append('value', dealId);
    let params = `/${projectId}/tags`
    axios({
      url: this.docvault_service.getServiceUrl("projects", "post")+params,
      method: 'post',
      data: data,
      transformRequest: [function (data, headers) {
        headers['Authorization'] = userToken;
        delete headers['Access-Control-Allow-Origin'];
        return data
      }],
      headers: {
        'Content-Type': 'multipart/form-data',
        "x-token-type": "Access",
      }
    }).then(response => {
      callback(response.data)
    }).catch(error => {
      window.messages.push({type: 'error', shown:false, message: error.response.data.responseException.exceptionMessage})
      callback(error.response.data)
    })
  }

  // add tag to project if folder name already exists
  tagProjectWithName(projectId, dealId, userToken, callback) {
    let data = new FormData();
    data.append('project_id', projectId);
    data.append('tag', "deal_uuid");
    data.append('value', dealId);
    let params = `/${projectId}/tags`
    axios({
      url: this.docvault_service.getServiceUrl("projects", "post")+params,
      method: 'post',
      data: data,
      transformRequest: [function (data, headers) {
        headers['Authorization'] = userToken;
        delete headers['Access-Control-Allow-Origin'];
        return data
      }],
      headers: {
        'Content-Type': 'multipart/form-data',
        "x-token-type": "Access",
      }
    }).then(response => {
      callback(response.data)
    }).catch(error => {
      window.messages.push({type: 'error', shown:false, message: error.response.data.responseException.exceptionMessage})
      callback(error.response.data)
    })
  }

  // get media by media id
  getMediaBymediaId(mediaId,  userToken, callback) {
    let data = new FormData();
    data.append('media_id', mediaId);
    let params = `/${mediaId}`
    axios({
      url: this.docvault_service.getServiceUrl("mediaById", "get")+params,
      method: 'get',
      data: data,
      transformRequest: [function (data, headers) {
        headers['Authorization'] = userToken;
        delete headers['Access-Control-Allow-Origin'];
        return data
      }],
      headers: {
        'Content-Type': 'multipart/form-data',
        "x-token-type": "Access",
      }
    }).then(response => {
      callback(response)
    }).catch(error => {
      console.log("error", error)
      // window.messages.push({type: 'error', shown:false, message: error.response.data.responseException.exceptionMessage})
      callback(error)
    })
  }

  // delete media by media id
  deleteMediaBymediaId(mediaId,  userToken, callback) {
    let data = new FormData();
    data.append('media_id', mediaId);
    let params = `/${mediaId}`
    axios({
      url: this.docvault_service.getServiceUrl("mediaById", "delete")+params,
      method: 'delete',
      data: data,
      transformRequest: [function (data, headers) {
        headers['Authorization'] = userToken;
        delete headers['Access-Control-Allow-Origin'];
        return data
      }],
      headers: {
        'Content-Type': 'multipart/form-data',
        "x-token-type": "Access",
      }
    }).then(response => {
      callback(response)
    }).catch(error => {
      console.log("error", error)
      // window.messages.push({type: 'error', shown:false, message: error.response.data.responseException.exceptionMessage})
      callback(error)
    })
  }

  getAttachmentCount(ids, userToken, callback){
    var mainArray = ids
    let data = new FormData();
    data.append('tags', JSON.stringify(mainArray))
    axios({
      url: this.docvault_service.getServiceUrl("mediaCount", "post"),
      method: 'post',
      data: data,
      transformRequest: [function (data, headers) {
        headers['Authorization'] = userToken;
        delete headers['Access-Control-Allow-Origin'];
        return data
      }],
      headers: {
        'Content-Type': 'multipart/form-data',
        "x-token-type": "Access",
      }
    }).then(response => {
      callback(response.data)
    }).catch(error => {
      callback(error.response)
    })
  }

  getWorkflowAttachmentCount(ids, userToken, callback){
    // var mainArray = {"deal_uuid": dealId,"workflow_uuid": workflowId,"log_uuid": logId}
    // var mainArray = {"workflow_uuid": workflowId, "task_uuid": taskId}
    let mainArray = ids
    let data = new FormData();
    data.append('tags', JSON.stringify(mainArray))
    // data.append('tags', JSON.stringify({"deal_uuid": dealId}));
    // data.append('tags', JSON.stringify({"workflow_uuid": workflowId}));
    // data.append('tags', JSON.stringify({"log_uuid": logId}));
    axios({
      url: this.docvault_service.getServiceUrl("attachmentCount", "post"),
      method: 'post',
      data: data,
      transformRequest: [function (data, headers) {
        headers['Authorization'] = userToken;
        delete headers['Access-Control-Allow-Origin'];
        return data
      }],
      headers: {
        'Content-Type': 'multipart/form-data',
        "x-token-type": "Access",
      }
    }).then(response => {
      callback(response.data)
    }).catch(error => {
      callback(error.response)
    })
  }

  // update media tag
  updateMediaTag(mediaId, tagName, tagValue,  userToken, callback) {
    let data = new FormData();
    data.append('media_id', mediaId);
    data.append('tag', tagName);
    data.append('value', tagValue);
    let params = `/${mediaId}/tags`
    axios({
      url: this.docvault_service.getServiceUrl("mediaTags", "patch")+params,
      method: 'patch',
      data: data,
      transformRequest: [function (data, headers) {
        headers['Authorization'] = userToken;
        delete headers['Access-Control-Allow-Origin'];
        return data
      }],
      headers: {
        'Content-Type': 'multipart/form-data',
        "x-token-type": "Access",
      }
    }).then(response => {
      callback(response)
    }).catch(error => {
      console.log("error", error)
      // window.messages.push({type: 'error', shown:false, message: error.response.data.responseException.exceptionMessage})
      callback(error)
    })
  }


  getTaskTags(pageData, sortData, callback) {
    let params = pageData

    axios({
      url: this.task_service.getServiceUrl("taskTags", "get")+params,
      method: 'get',
      data: sortData
    }).then(response => {
      callback(response.data)
    }).catch(error => {
      window.messages.push({type: 'error', shown:false, message: error.response.data.responseException.exceptionMessage})
      callback(error.response.data)
    })
  }

  updateTaskTags(dealIds, callback) {
    let params = ''

    if(dealIds.length == 1) {
      params = `?DealIds=${dealIds[0]}`
    }else {
      dealIds.forEach((dealId, index) => {
        if(index == 0) {
          params += `?DealIds=${dealId}`
        }else {
          params += `&DealIds=${dealId}`
        }
      })
    }

    axios({
      url: this.task_service.getServiceUrl("taskTags", "post")+params,
      method: 'post',
      data: []
    }).then(response => {
      callback(response.data)
    }).catch(error => {
      callback(error.response.data)
    })
  }

  taskSwitchUsers(data, callback) {
    this.task_service.fetch("switchUsers", callback,
    {
      method: "post",
      data: data,
      messages: {success: 'Users Switched Successfully', error:''}
    });
  }

  getTaskHistory(taskId, callback) {
    this.task_service.fetch("taskHistory", callback,
      {
        data: {taskId: taskId},
        messages: {error: ''}
      }
    );
  }

  getWorkflow(pageData, callback) {
    let params = pageData
    axios({
      url: this.task_service.getServiceUrl("workflow", "get")+params,
      method: 'get',
    }).then(response => {
      callback(response.data)
    }).catch(error => {
      window.messages.push({type: 'error', shown:false, message: error.response.data.responseException.exceptionMessage})
      callback(error.response.data)
    })
  }

  getWorkflowDatas(data, callback) {
    axios({
      url: this.task_service.getServiceUrl("workflow", "get"),
      method: 'post',
      data: data
    }).then(response => {
      callback(response.data)
    }).catch(error => {
      window.messages.push({type: 'error', shown:false, message: error.response.data.responseException.exceptionMessage})
      callback(error.response.data)
    })
  }

  getWorkflowById (workflowId, callback){
    this.task_service.fetch("workflowById", callback, {data: {workflowId: workflowId}, messages: {error: ''}});
  }

  updateWorkflowData(data, callback) {
    this.task_service.fetch("workflow", callback,
    {
      method: "put",
      data: data,
      messages: {success: 'Workflow updated successfully', error:''}
    });
  }

  updateTaskActivationOption(data, callback) {
    let successMessage = '';
    if(data.isActive == true)
      successMessage = 'Tasks Activated Successfully';
    else
      successMessage = 'Tasks Deactivated Successfully';

    this.task_service.fetch("setActivation", callback,
    {
      method: "put",
      data: data,
      messages: {success: `${successMessage}`, error:''}
    });
  }

  // Notifications
  getNotifications(callback) {
    this.task_service.fetch('notifications', callback,
    {
      method: 'get',
      messages: { error:'' },
      data: []
    });
  }

  clearNotifications(idList, callback) {
    let pageData = idList.map(id => id = `notificationIds=${id}`)
    pageData = pageData.join('&')
    let params = `?${pageData}`
    axios({
      url: this.task_service.getServiceUrl('notifications', 'put')+params,
      method: 'put',
    }).then(response => {
      callback(response.data)
    }).catch(error => {
      window.messages.push({type: 'error', shown:false, message: error.response.data.responseException.exceptionMessage})
      callback(error.response.data)
    })
  }

  dispacthNotifications(callback) {
    this.task_service.fetch('dispatchNotification', callback,
    {
      method: 'get',
      messages: { error:'' }
    });
  }

  assignTasksToMe(data, callback) {
    this.task_service.fetch('assignTasksToMe', callback,
      {
        method: 'post',
        messages: { success: 'Task assigned successfully', error: ''},
        data: data
      }
    );
  }

  assignWorkflowToMe(data, callback) {
    this.task_service.fetch('assignWorkflowToMe', callback,
      {
        method: 'post',
        messages: { success: 'Workflow and tasks assigned successfully', error: '' },
        data: data
      }
    );
  }

}

export default new TaskManager();
